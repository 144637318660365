import type { OnInit } from '@angular/core'
import { Component } from '@angular/core'
import { APIServiceService } from '../api/apiservice.service'
import { Router } from '@angular/router'
import { TitleService } from '../title.service'
import { environment } from '../../environments/environment'
import { LogglyService } from 'ngx-loggly-logger'
import { LoggingService } from '../logging.service'
import { CartServiceService } from '../cart-service.service'

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
    enableTakeout = environment.takeoutEnable
    enableDineIn = environment.dineInMenuEnable
    enablePay = environment.payEnable

    constructor(
        private api: APIServiceService,
        private router: Router,
        private cart: CartServiceService,
        private logger: LoggingService,
        private titleService: TitleService
    ) {
        titleService.setTitle(environment.titleFull, environment.titleFull)
    }

    ngOnInit(): void {
        this.logger.clear()
        this.logger.info('Landing', 'View Landing')
        this.cart.reset().subscribe()
    }
}
