import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import type { Observable } from 'rxjs'
import { EMPTY, of } from 'rxjs'
import {
    map,
    onErrorResumeNext,
    shareReplay,
    switchMap,
    tap,
} from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { EventService } from '../event.service'
import { LogglyService } from 'ngx-loggly-logger'
import { LoggingService } from '../logging.service'
import { VERSION } from '../../environments/version'
import { APIServiceService } from './apiservice.service'
import type { XMenuItem } from './menu.service'

@Injectable({
    providedIn: 'root',
})
export class CheckService {
    constructor(
        private api: APIServiceService,
        private logger: LoggingService,
        private eventService: EventService
    ) {}

    getDetailsFromNFCTag(tagUUID: string): Observable<XNFCResponse> {
        return this.api.get<XNFCResponse>('/api/v3/nfc/' + tagUUID).pipe(
            tap((x) => {
                // Enhance
                x.checks.forEach((c) => {
                    c.printString = c.print.map((l) => l.line)
                    this.enhanceCheck(c)
                })
            })
        )
    }

    getDetailsFrom6DigCode(code: string): Observable<XCheck> {
        return this.api.get<XChecks>('/api/v2/getcheck?code=' + code).pipe(
            map((checks) => checks.checks[0]), // Always one!
            map((c) => {
                return this.enhanceSingleCheck(c)
            })
        )
    }

    private enhanceSingleCheck(c: XSingleCheck) {
        c.check.print = c.print
        c.check.printString = c.print.map((l) => l.line)
        c.check.discounts = c.discounts
        c.check.payment_options = c.payment_options

        this.enhanceCheck(c.check)
        return c.check
    }

    private enhanceCheck(c: XCheck) {
        // TODO this below should be done on the server
        c.total = c.amount
        c.subtotal = c.amount
        return c
    }

    getCheckByLocCodeId(locCodeId: number): Observable<XCheck> {
        return this.api
            .get<XSingleCheck>('/api/v2/getcheckID/' + locCodeId)
            .pipe(
                map((c) => {
                    c.check.print = c.print
                    c.check.printString = c.print.map((l) => l.line)
                    c.check.discounts = c.discounts
                    c.check.payment_options = c.payment_options
                    this.enhanceCheck(c.check)
                    return c.check
                })
            )
    }
}

export interface XStructuredCheckResponse {
    check: XStructuredCheck //Should not use AgentCheck
    checkLocationCode: number //Should be in Check from XPOServer
}

//From Agent
//Shoud not be used!
export interface XStructuredCheck {
    locationCode: number

    checkName: string
    tableNum: string
    checkNum: string
    checkSeq: string
    closed: boolean
    hasAlcohol: boolean

    printedCheckByTable: string
    printedCheckBySeat: string

    totals: XTotals
}

export interface XTotals {
    amountdue: number
    servicecharge: number
    subtotal: number
    tax: number
}

export interface XStructureCheckDetail {
    internal: XInternal
    quantity: number
    amount: number
    ref: string
    cdMenuItem: XStructureCheckDetailMenuItem
    cdDiscount: XStructureCheckDetailDiscount
    cdTender: XStructureCheckDetailTender
    children: XStructureCheckDetail[]
}

export interface XStructureCheckDetailMenuItem {
    miSeq: number
}
export interface XStructureCheckDetailTender {
    tmedSeq: number
}
export interface XStructureCheckDetailDiscount {
    dsvcSeq: number
}

export interface XInternal {
    uuid: string
    mi: XMenuItem
    total: number // The price for one item

    totalWithChildren: number // The price for one item with all children
    totalWithChildrenXQty: number // The price for qty items with all children
}

export interface XNFCResponse {
    location_id: number
    rvcseq: number
    tablenum: number
    tblseq: number
    checks: XCheck[]
}

export interface XChecks {
    checks: XSingleCheck[]
}

interface XSingleCheck {
    check: XCheck
    discounts: string[]
    print: XLine[]
    payment_options: XCheckPayInfo

    // Enhanced
    printString: string[]
}

export interface XCheck {
    total: number // Cents
    subtotal: number // Cents
    amount: number // Amount Due
    tax: string // Sum of taxes

    checknum: number
    checkseq: string
    checkname: string
    code: string
    merchant: number
    country: string
    currency: string
    location: number
    locationcode: number
    square_location_id: string
    tablenum: string
    checktime: string
    closed: boolean
    server: string // EmployeeName

    // Sometimes there... sometimes with XSingleCheck
    print: XLine[]
    discounts: string[]

    // Enhanced
    printString: string[]
    payment_options: XCheckPayInfo
}

export interface XCheckPayInfo {
    maxCreditCard: number
    maxGiftCard: number
    maxWallet: number

    tipPercentDefault: number
    tipPercentChoices: number[]
}

export interface XLine {
    line: string
}
