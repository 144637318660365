// @ts-strict-ignore
import type { OnInit } from '@angular/core'
import { Component, HostBinding } from '@angular/core'
import { OverlayContainer } from '@angular/cdk/overlay'
import { environment } from '../environments/environment'
import {
    slideToRight,
    slideToLeft,
    slideUp,
    slideDown,
} from './router.animations'
import type { RouterOutlet } from '@angular/router'
import { transition, trigger } from '@angular/animations'
import { TitleService } from './title.service'
import { LogglyService } from 'ngx-loggly-logger'

@Component({
    selector: 'app-root',
    animations: [
        trigger('routeAnimation', [
            transition('* => LandingPage', slideUp()),

            transition('LandingPage => TakeoutLandingPage', slideToRight()),
            transition(
                'TakeoutLandingPage => DeliveryInfoPage',
                slideToRight()
            ),
            transition('DeliveryInfoPage => MenuPage', slideToRight()),
            transition('LandingPage => MenuPage', slideToRight()),
            transition('MenuPage => MenuGroupPage', slideToRight()),
            transition('MenuGroupPage => MenuPage', slideToLeft()),
            transition('MenuGroupPage => ItemConfigPage', slideToRight()),
            transition('ItemConfigPage => MenuGroupPage', slideToLeft()),
            transition('MenuGroupPage => CartPage', slideToRight()),
            transition('CartPage => MenuPage', slideToLeft()),
            transition('MenuPage => CartPage', slideToRight()),
            transition('CartPage => MenuGroupPage', slideToLeft()),
            transition('CartPage => CheckoutPage', slideToRight()),
            transition('CheckoutPage => CartPage', slideToLeft()),
            transition('CheckoutPage => TakeoutCompletedPage', slideToRight()),

            transition('LandingPage => MenuLanding', slideToRight()),
            transition('MenuLanding => MenuView', slideToRight()),
            transition('MenuView => MenuView', slideToRight()),
            transition('MenuView => MenuLanding', slideToLeft()),

            transition('LandingPage => PayEnterCode', slideToRight()),
            transition('PayEnterCode => PayView', slideToRight()),
            transition('PayView => PayEnterCode', slideToLeft()),
            transition('PayChoose => PayView', slideToRight()),
            transition('PayView => PayChoose', slideToLeft()),
            transition('PayView => PayCC', slideToRight()),
            transition('PayCC => PayView', slideToLeft()),
            transition('PayCC => PayGC', slideToRight()),
            transition('PayGC => PayCC', slideToLeft()),
            transition('PayCC => PayReceipt', slideToRight()),
            transition('PayGC => PayReceipt', slideToRight()),
            transition('PayReceipt => PayView', slideToLeft()),
        ]),
    ],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    // isIOS = this.deviceService.os === 'iOS' && environment.iosAppUrl !== '';
    // isAndroid = this.deviceService.os === 'Android' && environment.androidAppUrl !== '';

    @HostBinding('class') componentCssClass

    constructor(
        public titleService: TitleService,
        public logglyService: LogglyService,
        public overlayContainer: OverlayContainer
    ) {}

    ngOnInit(): void {
        this.titleService.setTitle('', '')

        const theme = environment.brand
        this.overlayContainer.getContainerElement().classList.add(theme)
        this.componentCssClass = theme

        this.logglyService.push({
            logglyKey: '429b4e29-dfff-4de7-bf6f-992ccd2f4c72',
            sendConsoleErrors: false,
            tag: 'xpoWeb',
        })
    }

    openApp() {
        // if (this.isIOS) {
        //   window.open(environment.iosAppUrl, '_blank');
        // } else if (this.isAndroid) {
        //   window.open(environment.androidAppUrl, '_blank');
        // }
    }

    scrollToTop() {
        window.scrollTo(0, 0)
    }

    prepRouteState(outlet: RouterOutlet) {
        return (
            outlet &&
            outlet.activatedRouteData &&
            outlet.activatedRouteData['animation']
        )
    }
}
