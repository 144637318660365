// import {animate, animateChild, animation, AnimationMetadata, group, query, style} from '@angular/animations';
//

import type {
    AnimationMetadata,
    AnimationTriggerMetadata,
} from '@angular/animations'
import {
    animateChild,
    group,
    query,
    trigger,
    useAnimation,
    animate,
    animation,
    AnimationReferenceMetadata,
    AnimationStyleMetadata,
    style,
    sequence,
    transition,
} from '@angular/animations'

// const stylePositionAbsolute = style({position: 'absolute', top: 0, left: 0, right: 0});
// const fadeOut = animation([animate('{{duration}}ms ease', style({opacity: 0})), style({display: 'none'})]);
// const fadeIn = animation([style({opacity: 0}), animate('{{duration}}ms ease', style({opacity: 1}))]);

//
// export function fadeInFadeOutRouteTransition(): AnimationMetadata[] {
//
//   return [
//     query(':enter, :leave', stylePositionAbsolute, {optional: true}),
//
//     sequence([
//       query(':leave', [
//         animateChild()
//       ], {optional: true}),
//       group([
//         query(':enter', [
//           style({opacity: '0%'}),
//           animate('1000ms ease', style({opacity: '100%'})),
//         ], {optional: true}),
//         // query(':leave', [
//         //   style({top: '0px'}),
//         //   animate('1000ms ease', style({top: '100%'})),
//         // ], {optional: true})
//       ]),
//       query(':enter ', [
//         animateChild()
//       ], {optional: true}),
//     ])
//   ];
// }
//

export function animateChildrenOnEnterAnimation(
    options?: any
): AnimationTriggerMetadata {
    return trigger((options && options.anchor) || 'animateChildrenOnEnter', [
        transition(':enter', [query('@*', animateChild(), { optional: true })]),
    ])
}

// const stylePositionAbsolute = style({position: 'absolute', left: 0, right: 0});
// const fadeOut = animation([animate('{{duration}}ms ease', style({opacity: 0})), style({display: 'none'})]);
// const fadeIn = animation([style({opacity: 0}), animate('{{duration}}ms ease', style({opacity: 1}))]);

export function slideToRight(): AnimationMetadata[] {
    return [
        style({ position: 'relative' }),
        query(
            ':enter, :leave',
            [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                }),
            ],
            { optional: true }
        ),
        query(':enter', [style({ left: '100%' })], { optional: true }),
        query(':leave', animateChild(), { optional: true }),
        group([
            query(
                ':leave',
                [animate('300ms ease-out', style({ left: '-100%' }))],
                { optional: true }
            ),
            query(
                ':enter',
                [animate('300ms ease-out', style({ left: '0%' }))],
                { optional: true }
            ),
        ]),
        query(':enter', animateChild(), { optional: true }),
    ]
}

export function slideUp(): AnimationMetadata[] {
    return [
        style({ position: 'relative' }),
        query(
            ':enter, :leave',
            [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                }),
            ],
            { optional: true }
        ),
        query(':enter', [style({ top: 1000 })], { optional: true }),
        query(':leave', animateChild(), { optional: true }),
        group([
            query(
                ':leave',
                [animate('200ms ease-out', style({ opacity: 0 }))],
                { optional: true }
            ),
            query(':enter', [animate('500ms ease-out', style({ top: 0 }))], {
                optional: true,
            }),
        ]),
        query(':enter', animateChild(), { optional: true }),
    ]
}

export function slideDown(): AnimationMetadata[] {
    return [
        style({ position: 'relative' }),
        query(
            ':enter, :leave',
            [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                }),
            ],
            { optional: true }
        ),
        query(':enter', [style({ top: -1000 })], { optional: true }),
        query(':leave', animateChild(), { optional: true }),
        group([
            query(
                ':leave',
                [animate('200ms ease-out', style({ opacity: 0 }))],
                { optional: true }
            ),
            query(':enter', [animate('500ms ease-out', style({ top: 0 }))], {
                optional: true,
            }),
        ]),
        query(':enter', animateChild(), { optional: true }),
    ]
}

export function slideToLeft(): AnimationMetadata[] {
    return [
        style({ position: 'relative' }),
        query(
            ':enter, :leave',
            [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                }),
            ],
            { optional: true }
        ),
        query(':enter', [style({ left: '-100%' })], { optional: true }),
        query(':leave', animateChild(), { optional: true }),
        group([
            query(
                ':leave',
                [animate('300ms ease-out', style({ left: '100%' }))],
                { optional: true }
            ),
            query(
                ':enter',
                [animate('300ms ease-out', style({ left: '0%' }))],
                { optional: true }
            ),
        ]),
        query(':enter', animateChild(), { optional: true }),
    ]
}
//
// export function fade(): AnimationMetadata[] {
//
//   return [
//     fadeOutDownOnLeaveAnimation(),
//     fadeInUpOnEnterAnimation()
//
//     // style({position: 'relative'}),
//
//     // query(':enter', [style({opacity: '0'})], {optional: true}),
//     //
//     // query(':leave', animateChild(), {optional: true}),
//     //
//     // group([
//     //   query(':leave', [fadeOutAnimation()], {optional: true}),
//     //   query(':enter', [jackInTheBoxAnimation()], {optional: true})
//     // ]),
//     //
//     // query(':enter', animateChild(), {optional: true})
//   ];
// }

//
// export function animateChildrenOnEnterAnimation(options?: any): AnimationTriggerMetadata {
//   return trigger((options && options.anchor) || 'animateChildrenOnEnter', [
//     transition(':enter', [query('@*', animateChild(), {optional: true})])
//   ]);
// }
