import { NgModule } from '@angular/core'
import type { Routes } from '@angular/router'
import { RouterModule } from '@angular/router'
import { LandingComponent } from './landing/landing.component'
import { TagRedirectComponent } from './tag-redirect/tag-redirect.component'

// The tag/:uuid is hardcoded into the NFC tags and cant be changed... hopefully wil migrate to /external
const routes: Routes = [
    {
        path: 'takeout',
        loadChildren: () =>
            import('./takeout/takeout.module').then((m) => m.TakeoutModule),
    },
    {
        path: 'pay',
        loadChildren: () => import('./pay/pay.module').then((m) => m.PayModule),
    },
    {
        path: 'menu',
        loadChildren: () =>
            import('./menu/menu.module').then((m) => m.MenuModule),
    },

    { path: 'tag/:uuid', redirectTo: 'external/tag/:uuid' },
    {
        path: 'external/tag/:uuid',
        component: TagRedirectComponent,
        data: { animation: 'LandingPage' },
    },
    { path: 'external/menu/:loc', redirectTo: '/menu/view/:loc' },
    { path: 'external/takeout/:loc', redirectTo: '/takeout/landing/:loc' },

    { path: 'external/check/:loccode_id', redirectTo: '/pay/view/:loccode_id' },

    { path: 'Pickup', redirectTo: '/takeout/landing/Pickup' },
    { path: 'Delivery', redirectTo: '/takeout/landing/Delivery' },
    { path: 'Gifting', redirectTo: '/takeout/landing/Gift' },

    {
        path: '**',
        component: LandingComponent,
        data: { animation: 'LandingPage' },
    },
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
