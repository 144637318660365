<app-header-image></app-header-image>


  <div class="body" fxLayout="column" fxLayoutAlign="center center">
    <div class="holdertitle">{{title}}</div>
    <ng-content class="envelope"></ng-content>
  </div>

<div class="actions">
  <ng-content select="span.buttons-left"></ng-content>
  <span class="spacer"></span>
  <ng-content select="span.buttons-right"></ng-content>
</div>
