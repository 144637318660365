<app-script-loader></app-script-loader>

<ngx-spinner
  bdColor=rgba(51,51,51,0.80)
  size="default"
  color="#fff"
  type="ball-beat"
  fullScreen="true">
</ngx-spinner>

<mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="start center">

  <button mat-button [routerLink]="['/']">
    <div class="valign-center" fxLayout="row" fxLayoutAlign="start center">
      <div id="headerImage"></div>
      <div class="title1">{{titleService.getToolbar1()}}</div>&nbsp;&nbsp;<div
      class="title2">{{titleService.getToolbar2()}}</div>
    </div>
  </button>

</mat-toolbar>

<div class="route-container"
     [@routeAnimation]="prepRouteState(outlet)"
     (@routeAnimation.start)="scrollToTop()"
>

<!--  <div>-->
    <router-outlet #outlet="outlet"></router-outlet>
<!--  </div>-->


</div>
