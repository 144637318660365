// @ts-strict-ignore
import type { OnInit } from '@angular/core'
import { Component, Inject } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { MatSnackBar } from '@angular/material/snack-bar'
import { HttpErrorResponse } from '@angular/common/http'
import { LoggingService } from '../logging.service'
import type { XNFCResponse } from '../api/check.service'
import { CheckService } from '../api/check.service'
import type { XLocation } from '../api/location.service'
import { LocationService } from '../api/location.service'
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators'
import { ConfigService, XConfig } from '../api/config.service'
import { CartServiceService } from '../cart-service.service'
import { environment } from '../../environments/environment'
import { DOCUMENT } from '@angular/common'

@Component({
    selector: 'app-tag-redirect',
    templateUrl: './tag-redirect.component.html',
    styleUrls: ['./tag-redirect.component.scss'],
})
export class TagRedirectComponent implements OnInit {
    uuid = this.activatedRoute.snapshot.paramMap.get('uuid')

    constructor(
        private api: LocationService,
        private apiConfig: ConfigService,
        private apiCheckService: CheckService,
        private cartService: CartServiceService,
        private router: Router,
        private snackBar: MatSnackBar,
        private logger: LoggingService,
        private activatedRoute: ActivatedRoute,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngOnInit(): void {
        this.logger.info('TagRedirect', 'Tapped tag: ' + this.uuid)

        this.apiCheckService
            .getDetailsFromNFCTag(this.uuid)
            .pipe(
                switchMap((tag) =>
                    this.api
                        .getLocation(tag.location_id)
                        .pipe(map((loc) => ({ tag, loc })))
                ),
                switchMap(({ tag, loc }) =>
                    this.cartService.changes$.pipe(
                        take(1),
                        map((cart) => ({ tag, loc, cart }))
                    )
                ),
                switchMap(({ tag, loc, cart }) =>
                    this.cartService
                        .setLocation(cart, loc)
                        .pipe(map((cart) => ({ tag, loc, cart })))
                )
            )
            .subscribe(
                ({ tag, loc, cart }) => this.handleRedirect(loc, tag),
                (e) => this.handleError(e)
            )
    }

    private handleError(error) {
        console.log(error)
        this.router.navigate(['/'])

        if (error instanceof HttpErrorResponse) {
            this.snackBar.open(error.error.message, 'Dismiss', {
                duration: 5000,
            })
        }
    }

    private handleRedirect(loc: XLocation, tag: XNFCResponse) {
        this.logger.addLocation(loc)
        this.logger.addTable('' + tag.tablenum)
        this.logger.addRvc('' + tag.rvcseq)

        if (tag.checks.length > 0) {
            this.logger.info(
                'TagRedirect',
                'Found ' + tag.checks.length + ' checks on tag'
            )
            this.router.navigate(['/pay/choose', this.uuid])
        } else {
            this.logger.info(
                'TagRedirect',
                'Found no checks on tag - redirect to internal menu'
            )
            this.router.navigate([
                '/menu/view/',
                tag.location_id,
                environment.dineInMenuId,
            ])
        }
    }
}
