import type { OnInit } from '@angular/core'
import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-holder',
    templateUrl: './holder.component.html',
    styleUrls: ['./holder.component.scss'],
})
export class HolderComponent {
    constructor() {}

    _title: string

    @Input()
    set title(s: string) {
        this._title = s
    }

    get title() {
        return this._title
    }
}
