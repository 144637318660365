import { Component } from '@angular/core'

@Component({
    selector: 'app-header-image',
    templateUrl: './header-image.component.html',
    styleUrls: ['./header-image.component.scss'],
})
export class HeaderImageComponent {
    constructor() {}
}
