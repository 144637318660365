import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import type { Observable } from 'rxjs'
import { shareReplay, tap } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { EventService } from '../event.service'
import { LoggingService } from '../logging.service'

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    private loginCache$ = this.loginDo().pipe(shareReplay(1))

    constructor(
        private http: HttpClient,
        private logger: LoggingService,
        private eventService: EventService
    ) {}

    login(): Observable<XLogin> {
        return this.loginCache$
    }

    private loginDo(): Observable<XLogin> {
        return this.getNoLogin<XLogin>(
            '/api/v2/anonLogin?brand=' + environment.brand
        ).pipe(
            tap((h) => {
                this.eventService.login(h)
                this.logger.addUser(h)
                this.logger.info(
                    'LoginService',
                    'Login ' + h.status + ' ' + h.login_status
                )
            })
        )
    }

    public getNoLogin<T>(
        url: string,
        paramsVal?: { [param: string]: string }
    ): Observable<T> {
        const httpGetOptions = {
            withCredentials: true,
            params: paramsVal,
        }

        console.log('Doing login call', url)
        return this.http.get<T>(url, httpGetOptions)
    }
}

export interface XLogin {
    status: string
    brand: string
    id: number
    login_status: string
}
