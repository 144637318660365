export const environment = {
  production: true,
  googleAPIKey: "AIzaSyD8E5ScX2arcU2K9BvhzCchs8yMTWRd180",
  squareUrl: "https://web.squarecdn.com/v1/square.js",
  merchantId: 2,
  processor: "SQUARE",
  brand: "SALTLIK",
  title: "Saltlik",
  giftCardEnable: false,
  takeoutEnable: false,
  giftingEnable: false,
  giftingMessageEnable: false,
  giftingMessage: "",
  payEnable: true,
  dineInMenuEnable: false,
  dineInMenuId: 6,
  takeoutMenuId: 1,

  feeFreeThreshold: 0,
  feeFreeHintThreshold: 0,
  feeMenuId: 14,
  feeDsvcSeq: 0,
  feeMenuCustPayUnder: 0,

  payTipDefaultPercent: 18,
  payTipPercentages: [15, 18, 20],
  deliveryDriverTipDefaultPercent: 15,
  deliveryDriverTipPercentages: [5, 10, 15],
  deliveryDriverTipMax: 10,
  deliveryLocationTipDefaultPercent: 0,
  deliveryLocationTipPercentages: [5, 10, 15],
  takeoutLocationTipDefaultPercent: 15,
  takeoutLocationTipPercentages: [12, 15, 20],
  driverTipDsvcSeq: 100,
  locationTipDsvcSeq: 100,

  titleFull: "",
  mapsAPIKey: "AIzaSyD8E5ScX2arcU2K9BvhzCchs8yMTWRd180",
  iosAppUrl: "",
  androidAppUrl: "",
  firebase: {
    apiKey: "AIzaSyCRmfhkEDFF-CW6jOk1Rh5g4savb4jwbv8",
    authDomain: "web-pay-e873c.firebaseapp.com",
    databaseURL: "https://web-pay-e873c.firebaseio.com",
    projectId: "web-pay-e873c",
    storageBucket: "web-pay-e873c.appspot.com",
    messagingSenderId: "318004698461",
    appId: "1:318004698461:web:7620c246e851f038419cb4",
    measurementId: "G-LYCEKGMEJS",
  },
  dcimages: {},
  alcoholProhibitedMajorGroups: ["LIQUOR"],
  alcoholProhibitedLocationIds: [18, 22, 42],
  alcoholProhibitedMenuIds: [1],
};
